import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fonts, mq, space } from 'folio-common-components';
import * as React from 'react';
import { NarrowPage } from './NarrowPage';

const Heading = styled.h1`
  ${fonts.font600book};
  ${space([48], 'margin-top')};
  ${space([24], 'margin-bottom')};
`;

export const FlowInfoPage: React.FC<{
  illustration: React.ReactElement;
  heading: React.ReactElement | string;
  body: React.ReactNode;
}> = ({ illustration, heading, body }) => (
  <NarrowPage
    css={css`
      ${space([4, 4, 64], 'margin-top')};
    `}
  >
    <div
      css={css`
        @media not all and ${mq.medium} {
          text-align: center;
        }
      `}
    >
      {illustration}
      <Heading>{heading}</Heading>
      <div>{body}</div>
    </div>
  </NarrowPage>
);
