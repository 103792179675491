import { css } from '@emotion/react';
import { colors } from 'folio-design-tokens';

export const hoverBubbleActiveStyling = css`
  opacity: 1;
  transform: scale(1);
`;

export const hoverBubbleStyling = css`
  position: relative;
  z-index: 0;

  ::before {
    content: '';
    position: absolute;
    top: -4px;
    right: -8px;
    bottom: -4px;
    left: -8px;
    z-index: -1;
    border-radius: 8px;
    background: var(--hover-bubble-color, ${colors.grayLight});
    will-change: transform, opacity;
    opacity: 0;
    transition: transform 0.3s, opacity 0.2s;
    transform-origin: var(--origin);

    @media (prefers-reduced-motion: no-preference) {
      transform: scale(0.4);
    }
  }

  @media (hover) {
    :hover::before {
      ${hoverBubbleActiveStyling};
    }
  }
`;
