import { css } from '@emotion/react';
import {
  Divider,
  DummyButton,
  TextButton,
  fonts,
  space,
} from 'folio-common-components';
import { invariant } from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import type { OrgState } from '../../gqltypes';
import { useInteractionIntent } from '../../hooks/use-interaction-intent';
import { useLocationMatch } from '../../hooks/use-location-match';
import type { MenuItem } from '../../hooks/use-menu-items';
import { useOrgSwitchUrl } from '../../hooks/use-switch-org-url';
import { SmallArrowRightIcon } from '../../icons';
import { useIntercomControls } from '../../intercom-context';
import { ColoredLabel } from '../colored-label';
import { OrgLink, useOrgNavigate } from '../org-navigation';

interface OrgItem {
  readonly name: string;
  readonly orgNum: string;
  readonly state: OrgState;
  readonly isInSession: boolean;
}

type CloseFun = () => void;

const menuLinkActiveStyle = css`
  color: ${colors.blue};
`;

// This is copied from LargeLink in TopLevelMenu. They should be merged, but
// they look slightly different today.
const menuLinkStyle = css`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  ${fonts.font200medium};
  text-decoration: none;
  color: ${colors.black};
  border: 2px solid transparent;
  position: relative;
  margin-bottom: 8px;
  transform-origin: var(--item-transform-origin, 50%);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;

  /* This makes sure there are no uncklickable areas between menu items, which looks
  better when moving the cursor between items since it avoids flashing the cursor. */
  ::before {
    content: '';
    position: absolute;
    /* 6 is: 8 (space between menu items) / 2 + 2 (border) */
    top: -6px;
    bottom: -6px;
    left: 0;
    right: 0;
  }

  @media (hover) {
    :hover {
      background: ${colors.grayAiryOpaque};
    }
  }

  :active {
    background: ${colors.grayAiryOpaque};
    @media (pointer: fine) {
      ${menuLinkActiveStyle};
    }
  }

  @media (pointer: coarse) and (prefers-reduced-motion: no-preference) {
    border-radius: 8px;
    scale: var(--item-scale, 1);
    will-change: scale;
    transition-property: scale, background;
    transition-duration: 0.4s;

    :active {
      --item-scale: 0.96;
      transition-timing-function: ease-out;
      transition-duration: 0.15s;
    }

    /* icon */
    > svg {
      scale: var(--icon-scale, 1);
      transition: scale 0.4s 0.05s;
    }
  }

  /* Don't let children mess with touch handling */
  > * {
    pointer-events: none;
  }
`;

const itemHeight = 50;

const NavMenu: React.FC<{
  menuItems: readonly MenuItem[];
  closeMenu: CloseFun;
}> = props => {
  const { closeMenu, menuItems } = props;
  const offsetTop = React.useRef(0);

  return (
    <div
      data-testid="nav-items"
      css={css`
        position: relative;
        --sliding-bar-y: 0;
        --sliding-bar-scale: 0.7;
        --sliding-bar-opacity: 0;
      `}
      onPointerMove={event => {
        if (event.pointerType === 'mouse') {
          return;
        }

        if (!offsetTop.current) {
          offsetTop.current = event.currentTarget.getBoundingClientRect().top;
        }

        const y =
          event.nativeEvent.clientY -
          offsetTop.current +
          window.scrollY -
          itemHeight / 2;
        const style = event.currentTarget.style;
        style.setProperty('--sliding-bar-y', `${y}px`);
        style.setProperty('--sliding-bar-scale', '1');
        style.setProperty('--sliding-bar-opacity', '1');
      }}
      onPointerLeave={event => {
        const style = event.currentTarget.style;
        style.removeProperty('--sliding-bar-scale');
        style.removeProperty('--sliding-bar-opacity');
      }}
    >
      <SlidingBar />
      {menuItems.map(item => (
        <NavMenuItem
          key={item.page.getUrl()}
          item={item}
          closeMenu={closeMenu}
        />
      ))}
    </div>
  );
};

const NavMenuItem: React.FC<{
  item: MenuItem;
  closeMenu: CloseFun;
}> = ({ item, closeMenu }) => {
  const eventListeners = useInteractionIntent(() =>
    item.page.loadableComponent.preload(),
  );
  const path = item.page.getUrl();
  const { isPartialMatch } = useLocationMatch(path);
  const navigate = useOrgNavigate();

  // Track whether the menu item got a pointerdown event. If it did,
  // slide transitions are prevented.
  const gotPointerDown = React.useRef(false);
  const gotPointerMove = React.useRef(false);

  invariant(item.labelComponent);

  return (
    <OrgLink
      to={path}
      state={{ navigatedViaMenu: true }}
      css={[menuLinkStyle, isPartialMatch && menuLinkActiveStyle]}
      onClick={closeMenu}
      onPointerOver={event => {
        event.stopPropagation();

        if (event.pointerType === 'mouse' || gotPointerDown.current) {
          return;
        }

        event.currentTarget.style.setProperty('--item-scale', '1.12');
        event.currentTarget.style.setProperty('--icon-scale', '1.12');
        event.currentTarget.style.setProperty('z-index', '1');
      }}
      onPointerOut={event => {
        event.stopPropagation();

        gotPointerDown.current = false;
        gotPointerMove.current = false;
        event.currentTarget.style.removeProperty('--item-scale');
        event.currentTarget.style.removeProperty('--icon-scale');
        event.currentTarget.style.removeProperty('z-index');
      }}
      onPointerDown={event => {
        event.stopPropagation();

        if (event.target instanceof Element) {
          event.target.releasePointerCapture(event.pointerId);
        }

        gotPointerDown.current = true;

        // This is a press and not a slide so we want scaling and the transform
        // origin reset since press has another transition
        event.currentTarget.style.removeProperty('--item-scale');
        event.currentTarget.style.removeProperty('--item-transform-origin');
        event.currentTarget.style.removeProperty('--icon-scale');
      }}
      onPointerUp={event => {
        event.stopPropagation();

        // This prevents an issue where a "ghost click" is dispatched on
        // an element beneath the menu, when an item in the menu is clicked.
        if (!gotPointerMove.current) {
          return;
        }

        navigate(path);
        closeMenu();
      }}
      onPointerMove={event => {
        if (event.pointerType === 'mouse' || gotPointerDown.current) {
          event.stopPropagation();
          return;
        }

        gotPointerMove.current = true;

        // 55px is right between the icon and the text
        const originX = '55px'; // `${(100 - (event.nativeEvent.offsetX / 334) * 100) * 0.2}%`
        const originY = `${
          100 - (event.nativeEvent.offsetY / itemHeight) * 100
        }%`;

        event.currentTarget.style.setProperty(
          '--item-transform-origin',
          `${originX} ${originY}`,
        );
      }}
      preloadQueries={item.page.preloadQueries}
      {...eventListeners}
    >
      {item.icon}{' '}
      <span
        css={css`
          padding-left: 8px;
        `}
      >
        <item.labelComponent isActive={false} />
      </span>
    </OrgLink>
  );
};

const SlidingBar: React.FC = () => {
  return (
    <div
      css={css`
        @media (pointer: coarse) {
          position: absolute;
          left: -8px;
          right: -8px;
          height: ${itemHeight}px;
          background: ${colors.grayAiryOpaque};
          border-radius: 8px;
          pointer-events: none;
          z-index: -1;
          translate: 0 var(--sliding-bar-y);
          scale: var(--sliding-bar-scale);
          opacity: var(--sliding-bar-opacity);
          will-change: translate, scale, opacity;
          transition: scale 0.25s, opacity 0.25s;
        }
      `}
    />
  );
};

const LogOutLink: React.FC = () => {
  const intercomControls = useIntercomControls();
  return (
    <form
      css={css`
        ${space([32], 'margin-top')};
        ${space([16], 'margin-bottom')};
        text-align: center;
      `}
      method="post"
      action="/logg-ut"
      onSubmit={event => {
        const { currentTarget } = event;
        event.preventDefault();
        // Wait a bit and hope that the shutdown finishes before submitting
        setTimeout(() => currentTarget.submit(), 50);
        intercomControls.shutdown();
      }}
    >
      <TextButton type="submit">Logg ut</TextButton>
    </form>
  );
};

const Status: React.FC<{
  isInSession: boolean;
  isArchivedOrg: boolean;
  isOnboardingOrg: boolean;
}> = ({ isInSession, isArchivedOrg, isOnboardingOrg }) => {
  if (isArchivedOrg) {
    return (
      <ColoredLabel
        color="yellow"
        css={css`
          margin-left: 8px;
        `}
      >
        Avsluttet
      </ColoredLabel>
    );
  }

  return (
    <>
      {isInSession || isOnboardingOrg ? null : (
        <ColoredLabel
          color="green"
          css={css`
            margin-left: 8px;
          `}
        >
          Ny
        </ColoredLabel>
      )}
      {isOnboardingOrg ? (
        <ColoredLabel
          color="green"
          css={css`
            margin-left: 8px;
          `}
        >
          Bestilling
        </ColoredLabel>
      ) : null}
    </>
  );
};

const OrgMenuLink: React.FC<{ org: OrgItem }> = ({ org }) => {
  const url = useOrgSwitchUrl(org);
  const isOnbardingOrg =
    org.state === 'NewFounding' || org.state === 'Onboarding';
  return (
    <a
      href={url}
      css={css`
        text-decoration: none;
        display: inline-block;
        width: 100%;
        -webkit-tap-highlight-color: transparent;
      `}
    >
      <DummyButton
        fullWidth
        level="secondary"
        size="large"
        css={css`
          ${fonts.font200book};
        `}
      >
        <span
          css={css`
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
          `}
        >
          {org.name}
        </span>
        <Status
          isInSession={org.isInSession}
          isArchivedOrg={org.state === 'Archived'}
          isOnboardingOrg={isOnbardingOrg}
        />
        <span
          css={css`
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            ${space([16], 'margin-left')}
            color: ${colors.wcagNonText};
          `}
        >
          <SmallArrowRightIcon />
        </span>
      </DummyButton>
    </a>
  );
};

const OrgMenu: React.FC<{ orgItems: readonly OrgItem[] }> = props => {
  return (
    <ol
      data-testid="org-items"
      css={css`
        padding: 0;
        margin: 0;
        list-style: none;
      `}
    >
      {props.orgItems.map(item => {
        return (
          <li
            key={item.orgNum}
            css={css`
              ${space([8], 'padding-bottom')};
              &:last-of-type {
                padding-bottom: 0;
              }
            `}
          >
            <OrgMenuLink org={item} />
          </li>
        );
      })}
    </ol>
  );
};

interface Props {
  menuItems: readonly MenuItem[];
  orgItems?: readonly OrgItem[];
  closeMenu: () => void;
}

export const NavMenuContent: React.FC<Props> = props => {
  const { closeMenu, menuItems, orgItems } = props;

  return (
    <div
      css={css`
        ${space([16], 'padding')}
      `}
    >
      {menuItems.length ? (
        <>
          <NavMenu menuItems={menuItems} closeMenu={closeMenu} />
          <Divider css={space([16], 'margin-vertical')} />
        </>
      ) : null}
      {orgItems?.length ? <OrgMenu orgItems={orgItems} /> : null}
      <LogOutLink />
    </div>
  );
};
