import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import { useUnbatchedQuery } from 'bank-common-client';
import {
  centralBankInterestRateFormatted,
  lowerInterestFormatted,
} from 'folio-central-interest-rate';
import {
  Button,
  NumberValueInput,
  TextButton,
  fonts,
  space,
} from 'folio-common-components';
import { formatters, numbers } from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { InProgressView } from '../../components/InProgress';
import { BackLink } from '../../components/back-link';
import { ForbiddenPage, NotFoundErrorPage } from '../../components/error-pages';
import { FlowInfoPage } from '../../components/flow-info-page';
import { InfoBox } from '../../components/info-box';
import { OrgLink, useOrgNavigate } from '../../components/org-navigation';
import { PageCard } from '../../components/page-card';
import { PageHeader } from '../../components/page-header';
import { PageHeading } from '../../components/page-heading';
import { useHasCapability } from '../../hooks/use-capability-check';
import { useFirstPaintData } from '../../hooks/use-first-paint-data';
import { useScrollToTop } from '../../hooks/use-scroll-to-top';
import { useTitle } from '../../hooks/use-title';
import { CoinsIcon } from '../../icons';
import {
  FailureMarkIllustration,
  SuccessMarkIllustration,
} from '../../illustrations';
import { pages } from '../../pages';
import {
  EarmarksDocument,
  TransferFromEarmarkToOperationalDocument,
  TransferFromOperationalToEarmarkDocument,
  TransferFromSavingsAccountDocument,
  TransferToSavingsAccountDocument,
} from './queries.generated';
import { getComputedEarmarks } from '.';

export const TransferMoneyToOrFromSavingsAccount: React.FC<{
  toSavings: boolean;
}> = ({ toSavings }) => {
  useTitle('Sparekonto');
  const [amount, setAmount] = React.useState('');
  const [transfer, { data: transferData, loading, error }] = useMutation(
    toSavings
      ? TransferToSavingsAccountDocument
      : TransferFromSavingsAccountDocument,
    { variables: { amount } },
  );
  const { data } = useFirstPaintData();
  const canAuthorizePayment = useHasCapability('CanAuthorizePayment');
  const [hasError, setHasError] = React.useState(false);
  const navigate = useOrgNavigate();

  React.useEffect(() => {
    setHasError(error != null);
  }, [error]);

  if (!data || canAuthorizePayment == null) {
    return null;
  }

  if (!canAuthorizePayment) {
    return <ForbiddenPage />;
  }

  if (hasError) {
    return (
      <FlowInfoPage
        illustration={<FailureMarkIllustration />}
        heading="Overføringen feilet"
        body={
          <>
            <p>Det var rusk i systemet. Kontakt oss hvis det gjentar seg!</p>
            <Button onClick={() => setHasError(false)}>Prøv igjen</Button>
          </>
        }
      />
    );
  }

  const savingsAccount = data.accountsInfo.accounts.find(
    account => account.type === 'Savings',
  );
  const { operationalAccount } = data.organization;

  if (
    !savingsAccount ||
    savingsAccount.balanceNok == null ||
    savingsAccount.accruedInterest == null ||
    !operationalAccount ||
    operationalAccount.balanceNok == null
  ) {
    return <ForbiddenPage />;
  }

  const savingsAccountBalance = savingsAccount.balanceNok.asNumber;
  const savingsAccountAccruedBalance = savingsAccount.accruedInterest.asNumber;
  const operationalAccountBalance = operationalAccount.balanceNok.asNumber;

  const amountAsNumber = amount ? numbers.parseNumber(amount) : 0;

  const transferringTooMuch =
    amountAsNumber >
    (toSavings ? operationalAccountBalance : savingsAccountBalance);

  const formattedOperationalAccountAmount = formatAmount(
    operationalAccountBalance,
  );
  const formattedSavingsAccountAmount = formatAmount(savingsAccountBalance);

  if (transferData != null) {
    const formattedAmount = formatAmount(amountAsNumber);

    return (
      <FlowInfoPage
        illustration={<SuccessMarkIllustration />}
        heading={toSavings ? 'Pengene er satt av' : 'Pengene er overført'}
        body={
          <>
            <p>
              {toSavings
                ? `${formattedAmount} ble satt av på sparekonto. Ny sparesaldo er ${formattedSavingsAccountAmount}.`
                : `${formattedAmount} ble hentet fra sparing til drift. Ny saldo på driftskonto er ${formattedOperationalAccountAmount}.`}
            </p>
            <OrgLink to={pages.transactions.getUrl()}>Til oversikten</OrgLink>
          </>
        }
      />
    );
  }

  const formattedSavingsAccountAccruedBalance = formatters.formatAmount(
    savingsAccountAccruedBalance,
    { currency: 'kr' },
  );

  const transferringTooMuchErrorMessage = toSavings
    ? `Ikke nok på driftskonto (${formattedOperationalAccountAmount})`
    : `Ikke nok på sparekonto (${formattedSavingsAccountAmount})`;

  return (
    <>
      <BackLink url={pages.transactions.getUrl()} label="Oversikt" />
      <PageHeader>
        <PageHeading>
          {toSavings ? 'Sett av på sparekonto' : 'Hent ut fra sparekonto'}
        </PageHeading>
        <p>
          Saldo: {formattedSavingsAccountAmount}
          {savingsAccountAccruedBalance > 0
            ? ` + ${formattedSavingsAccountAccruedBalance} til gode i renter`
            : ''}
        </p>
      </PageHeader>
      <PageCard>
        <NumberValueInput
          label="Beløp"
          value={amount}
          onChange={setAmount}
          allowFractions={true}
          autoComplete="off"
          placeholder="0"
          icon={<CoinsIcon />}
          size="large"
          css={css`
            text-align: left;
          `}
          message={transferringTooMuch ? { kind: 'error' } : undefined}
        />
        {transferringTooMuch ? (
          <p
            css={css`
              color: ${colors.wcagRed};
            `}
          >
            {transferringTooMuchErrorMessage}
          </p>
        ) : (
          <p>
            {amount === '' ? (
              toSavings ? (
                <>
                  Du overfører fra driftskonto (
                  {formattedOperationalAccountAmount})
                </>
              ) : (
                <>Du overfører til driftskonto</>
              )
            ) : toSavings ? (
              <>
                Ny saldo på driftskonto blir{' '}
                <b>
                  {formatAmount(operationalAccountBalance - amountAsNumber)}
                </b>{' '}
                og ny sparesaldo blir{' '}
                <b>{formatAmount(savingsAccountBalance + amountAsNumber)}</b>
              </>
            ) : (
              <>
                Ny sparesaldo blir{' '}
                <b>{formatAmount(savingsAccountBalance - amountAsNumber)}</b> og
                ny saldo på driftskonto blir{' '}
                <b>
                  {formatAmount(operationalAccountBalance + amountAsNumber)}
                </b>
              </>
            )}
          </p>
        )}
        <Button
          onClick={async () => {
            if (loading || transferringTooMuch) {
              return;
            }

            try {
              await transfer();
            } catch {
              // ignore, this is handled via the mutation's error
            }
          }}
          disabled={amountAsNumber <= 0}
          fullWidth={true}
          size="large"
          loading={loading}
        >
          Overfør
        </Button>
        <div
          css={css`
            ${space([24], 'margin-top')};
            text-align: center;
          `}
        >
          <TextButton onClick={() => navigate(pages.transactions.getUrl())}>
            Avbryt
          </TextButton>
        </div>
      </PageCard>
      <InfoBox css={space([16], 'margin-top')}>
        <div css={fonts.font300demi}>Sparerente</div>
        <div>
          Folio følger styringsrenta fra Norges Bank, for tiden{' '}
          <b>{centralBankInterestRateFormatted}</b> per år. For innskudd over 2
          millioner er renten {lowerInterestFormatted}. Rentene betales til
          kontoen på slutten av året.
        </div>
      </InfoBox>
    </>
  );
};

type Direction = 'operational-to-earmark' | 'earmark-to-operational';

export const TransferToOrFromEarmark: React.FC<{
  direction: Direction;
}> = ({ direction }) => {
  useTitle('Sparekonto');
  const [amount, setAmount] = React.useState('');
  const { fid } = useParams();
  const toEarmark = direction === 'operational-to-earmark';
  const [transfer, { data: transferData, loading, error }] = useMutation(
    toEarmark
      ? TransferFromOperationalToEarmarkDocument
      : TransferFromEarmarkToOperationalDocument,
  );
  const { data } = useUnbatchedQuery(EarmarksDocument);
  const canAuthorizePayment = useHasCapability('CanAuthorizePayment');
  const [hasError, setHasError] = React.useState(false);
  const navigate = useOrgNavigate();
  useScrollToTop(transferData);

  React.useEffect(() => {
    setHasError(error != null);
  }, [error]);

  if (!fid) {
    return <NotFoundErrorPage />;
  }

  if (!data) {
    return <InProgressView />;
  }

  const { earmarks, operationalAccount, savingsAccount } = data.organization;

  if (!canAuthorizePayment || earmarks.length === 0) {
    return <ForbiddenPage />;
  }

  if (hasError) {
    return (
      <FlowInfoPage
        illustration={<FailureMarkIllustration />}
        heading="Overføringen feilet"
        body={
          <>
            <p>Det var rusk i systemet. Kontakt oss hvis det gjentar seg!</p>
            <Button onClick={() => setHasError(false)}>Prøv igjen</Button>
          </>
        }
      />
    );
  }

  if (
    !savingsAccount ||
    savingsAccount.balanceNok == null ||
    savingsAccount.accruedInterest == null ||
    !operationalAccount ||
    operationalAccount.balanceNok == null
  ) {
    return <ForbiddenPage />;
  }

  const subjectIsSyntheticEarmark = fid === 'annet';
  const computedEarmarks = getComputedEarmarks(data.organization);

  /**
   * The earmark to transfer in or out of.
   */
  const earmark = computedEarmarks?.find(earmark => {
    if (subjectIsSyntheticEarmark) {
      return earmark.kind === 'synthetic';
    } else {
      return earmark.kind === 'real' && earmark.fid === fid;
    }
  });

  // We couldn't figure out which earmark the user wants to move funds in
  // or out of.
  if (!earmark) {
    return <NotFoundErrorPage />;
  }

  const earmarkLabel = earmark.name.toLowerCase();
  const earmarkBalance = earmark.amount;
  const formattedEarmarkAmount = formatAmount(earmarkBalance);
  const operationalAccountBalance = operationalAccount.balanceNok.asNumber;
  const amountAsNumber = amount ? numbers.parseNumber(amount) : 0;
  const formattedOperationalAccountAmount = formatAmount(
    operationalAccountBalance,
  );

  if (transferData != null) {
    const formattedAmount = formatAmount(amountAsNumber);

    const text = toEarmark
      ? `${formattedAmount} ble overført til ${earmarkLabel}. Nå er
      ${formattedEarmarkAmount} satt av til dette.`
      : `${formattedAmount} ble hentet fra ${earmarkLabel} til drift. Ny saldo på driftskonto er ${formattedOperationalAccountAmount}`;

    return (
      <FlowInfoPage
        illustration={<SuccessMarkIllustration />}
        heading={toEarmark ? 'Pengene er satt av' : 'Hentet ut'}
        body={
          <>
            <p>{text}</p>
            <OrgLink to={pages.earmarks.getUrl()}>Tilbake til sparing</OrgLink>
          </>
        }
      />
    );
  }

  const transferringTooMuch =
    amountAsNumber > (toEarmark ? operationalAccountBalance : earmarkBalance);
  const transferringTooMuchErrorMessage = toEarmark
    ? `Ikke nok på driftskonto (${formattedOperationalAccountAmount})`
    : 'Ikke så mye satt av her';

  return (
    <>
      <BackLink url={pages.earmarks.getUrl()} label="Sparing" />
      <PageHeader>
        <PageHeading>
          {toEarmark
            ? `Sett av til ${earmarkLabel}`
            : `Hent ut fra ${earmarkLabel}`}
        </PageHeading>
        <p>{formattedEarmarkAmount} står her nå</p>
      </PageHeader>
      <PageCard>
        <form
          noValidate
          onSubmit={async event => {
            event.preventDefault();

            if (loading || transferringTooMuch) {
              return;
            }

            try {
              const earmarkFid = subjectIsSyntheticEarmark ? undefined : fid;
              await transfer({ variables: { earmarkFid, amount } });
            } catch {
              // ignore, this is handled via the mutation's error
            }
          }}
        >
          <NumberValueInput
            label="Beløp"
            value={amount}
            onChange={value => {
              // If the form has been submitted, don't allow input
              if (loading) {
                return;
              }

              setAmount(value);
            }}
            allowFractions={true}
            autoComplete="off"
            placeholder="0"
            icon={<CoinsIcon />}
            size="large"
            css={css`
              text-align: left;
            `}
            message={transferringTooMuch ? { kind: 'error' } : undefined}
            rightContent={{ content: 'kr', width: 14 }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
          {transferringTooMuch ? (
            <p
              css={css`
                color: ${colors.wcagRed};
              `}
            >
              {transferringTooMuchErrorMessage}
            </p>
          ) : (
            <p>
              {amount === '' ? (
                toEarmark ? (
                  <>
                    Du overfører fra driftskonto (
                    {formattedOperationalAccountAmount})
                  </>
                ) : (
                  <>
                    Du overfører til driftskonto (
                    {formattedOperationalAccountAmount})
                  </>
                )
              ) : toEarmark ? (
                <>
                  Du vil få{' '}
                  <b>{formatAmount(earmarkBalance + amountAsNumber)}</b> til{' '}
                  {earmarkLabel} totalt og{' '}
                  <b>
                    {formatAmount(operationalAccountBalance - amountAsNumber)}
                  </b>{' '}
                  på driftskonto
                </>
              ) : (
                <>
                  Du vil få{' '}
                  <b>{formatAmount(earmarkBalance - amountAsNumber)}</b> til{' '}
                  {earmarkLabel} totalt og{' '}
                  <b>
                    {formatAmount(operationalAccountBalance + amountAsNumber)}
                  </b>{' '}
                  på driftskonto
                </>
              )}
            </p>
          )}
          <Button
            disabled={amountAsNumber <= 0}
            fullWidth={true}
            size="large"
            loading={loading}
            type="submit"
          >
            Overfør
          </Button>
          <div
            css={css`
              ${space([24], 'margin-top')};
              text-align: center;
            `}
          >
            <TextButton onClick={() => navigate(pages.earmarks.getUrl())}>
              Avbryt
            </TextButton>
          </div>
        </form>
      </PageCard>
    </>
  );
};

export function formatAmount(amount: number) {
  return formatters.formatAmount(amount, { currency: 'kr' });
}
