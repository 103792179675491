import { VisuallyHidden } from 'folio-common-components';
import * as React from 'react';
import { lazyWithPreload } from 'react-lazy-with-preload';
import { Navigate } from 'react-router-dom';
import { BroadcastBox } from '../../components/Broadcast';
import {
  ActivateCard,
  BeforeFirstCardAccountTransferHint,
  BeforeFirstFolioAccountTransferHint,
  FoundingIntroHint,
} from '../../components/hints';
import { InfoPage } from '../../components/info-page';
import { NeedsKycBanner } from '../../components/needs-kyc-banner';
import { PageHeading } from '../../components/page-heading';
import { useFirstPaintData } from '../../hooks/use-first-paint-data';
import { useTitle } from '../../hooks/use-title';
import {
  FishyIllustration,
  TreasureChestIllustration,
} from '../../illustrations';
import { TransactionsView } from './NewTransactionsView';
import { PauseFish } from './PauseFish';
import { TransactionHeading } from './TransactionHeading';
import { FoundingSplash } from './onboarding-status/FoundingSplash';
import { InProgressView } from './onboarding-status/shared';

const OnboardingStateScreen = lazyWithPreload(
  () => import('./onboarding-status'),
);

export const TransactionsPage: React.FC = () => {
  useTitle('Oversikt');

  return (
    <>
      <BroadcastBox scopes={['CardTransfers', 'Transactions']} />
      <FoundingIntroHint />
      <ActivateCard />
      <BeforeFirstFolioAccountTransferHint />
      <BeforeFirstCardAccountTransferHint />
      <NeedsKycBanner />
      <VisuallyHidden>
        <PageHeading>Oversikt</PageHeading>
      </VisuallyHidden>
      <TransactionHeading />

      <VisuallyHidden>
        <h2>Bevegelser</h2>
      </VisuallyHidden>

      <TransactionsView />
    </>
  );
};

const MainPage: React.FC = () => {
  const { data: firstPaintData } = useFirstPaintData();
  if (!firstPaintData) {
    return null;
  }
  const { organization, me } = firstPaintData;
  const { name: orgName, state: orgState } = organization;
  const { activeHints, latestPromotion, state: agentState, type } = me;

  if (
    (orgState === 'Active' || orgState === 'ReadyToSignFounding') &&
    type === 'CoFounder' &&
    (latestPromotion == null ||
      latestPromotion.state === 'AdminSigning' ||
      latestPromotion.state === 'NeedsSigning' ||
      latestPromotion.state === 'Expired')
  ) {
    return (
      <InfoPage
        illustration={<TreasureChestIllustration />}
        heading="Spør om å få Folio"
        body={
          <p>
            Finn ut av hvem som administrerer Folio i bedriften, og be om kort
            og tilgang.
          </p>
        }
      />
    );
  }

  // for some reason, the redirect in `useFirstPaintData` doesn't work when deployed
  if (orgState === 'Rejected') {
    return (
      <Navigate
        to="/logg-inn/velg-organisasjon"
        replace={true}
        state={{ rejectedOrgName: orgName }}
      />
    );
  }

  if (type === 'Invitee') {
    return (
      <InfoPage
        illustration={<FishyIllustration />}
        heading="Venter på bekreftelse"
        body={
          <>
            <p>
              Vi har bedt en administrator i {orgName} bekrefte at du er rett
              person.
            </p>
            <p>Vi sender deg en e-post når det er gjort.</p>
          </>
        }
      />
    );
  }

  // TODO: we want to show a "welcome" screen when the org state switches to `Active` for the first time
  if (
    agentState === 'Onboarding' ||
    orgState === 'Onboarding' ||
    (agentState !== 'Active' && latestPromotion?.state === 'AgentSigning')
  ) {
    return (
      <React.Suspense fallback={<InProgressView />}>
        <OnboardingStateScreen orgState={orgState} />
      </React.Suspense>
    );
  }

  if (agentState !== 'Active') {
    return <PauseFish />;
  }

  if (activeHints.some(hint => hint.kind === 'afterFoundingActivation')) {
    return <FoundingSplash />;
  }

  return <TransactionsPage />;
};

// React.lazy only supports default exports:
// https://reactjs.org/docs/code-splitting.html#named-exports
// eslint-disable-next-line import/no-default-export
export default MainPage;
