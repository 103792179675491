import { css } from '@emotion/react';
import { useUnbatchedQuery } from 'bank-common-client';
import {
  centralBankInterestRateFormatted,
  lowerInterestFormatted,
} from 'folio-central-interest-rate';
import { fonts, mq, space } from 'folio-common-components';
import { invariant, listSum } from 'folio-common-utils';
import * as React from 'react';
import { InProgressView } from '../../components/InProgress';
import { BackLink } from '../../components/back-link';
import { cardStyle } from '../../components/card-style';
import { ForbiddenPage } from '../../components/error-pages';
import { InfoBox } from '../../components/info-box';
import { OrgLink } from '../../components/org-navigation';
import { PageHeader } from '../../components/page-header';
import { PageHeading } from '../../components/page-heading';
import { useHasCapability } from '../../hooks/use-capability-check';
import { useChangedEvents } from '../../hooks/use-changed-events';
import { CoinsIcon } from '../../icons';
import { pages } from '../../pages';
import { getIcon } from '../../utils/get-icon';
import { EarmarksDocument, type EarmarksQuery } from './queries.generated';
import { formatAmount } from './shared';

type ComputedEarmark =
  | {
      kind: 'real';
      amount: number;
      fid: string;
      icon: string;
      name: string;
    }
  | {
      kind: 'synthetic';
      amount: number;
      icon: string;
      name: string;
    };

/**
 * Returns the information needed to render correct info about earmarks.
 * If the org has an earmark called "Andre ting", then it's removed.
 * In it's place a "synthetic" earmark is returned, with no FID, as it's not
 * a server side earmark. This earmark will contain all funds in the account
 * that are not in an existing earmark.
 * This is to make sure that we handle transfers to the account gracefully when
 * the transfer did not include earmark info.
 */
export function getComputedEarmarks(
  org: EarmarksQuery['organization'],
): readonly ComputedEarmark[] | undefined {
  const { earmarks, savingsAccount } = org;
  if (savingsAccount == null) {
    return undefined;
  }
  invariant(savingsAccount?.balanceNok != null);

  const nonOtherEarmarks = earmarks.filter(e => e.name !== 'Andre ting');
  const nonOtherEarmarksBalance = listSum(
    nonOtherEarmarks.map(e => e.amount.asNumber ?? 0),
  );

  const savingsAccountBalance = savingsAccount.balanceNok.asNumber;
  const amountNotInEarmarks = savingsAccountBalance - nonOtherEarmarksBalance;

  return [
    // All the existing earmarks, except the one for "Andre ting" if
    // it existed.
    ...nonOtherEarmarks.map(({ amount, fid, icon, name }) => ({
      kind: 'real' as const,
      amount: amount.asNumber,
      fid,
      icon,
      name,
    })),

    // Synthetic earmark for "Andre ting", without a FID, since we wont be
    // using that as an earmark in core any more.
    {
      kind: 'synthetic' as const,
      amount: amountNotInEarmarks,
      icon: 'QuestionIcon',
      name: 'Andre ting',
    },
  ];
}

const Earmarking: React.FC = () => {
  const { data, refetch } = useUnbatchedQuery(EarmarksDocument, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 1000 * 60 * 10,
  });
  const canListEarmarks = useHasCapability('CanListPayments'); // Use this as a proxy for now

  const handleChangedEvents = React.useCallback(() => refetch(), [refetch]);
  useChangedEvents(handleChangedEvents);

  if (!data) {
    return <InProgressView />;
  }

  const { earmarks, savingsAccount } = data.organization;

  if (
    !canListEarmarks ||
    !savingsAccount ||
    savingsAccount.balanceNok == null ||
    earmarks.length === 0
  ) {
    return <ForbiddenPage />;
  }

  const savingsAccountBalance = savingsAccount.balanceNok.asNumber;
  const formattedBalance = formatAmount(savingsAccountBalance);
  const formattedInterest = formatAmount(
    savingsAccount.accruedInterest?.asNumber ?? 0,
  );

  const computedEarmarks = getComputedEarmarks(data.organization);

  return (
    <>
      <PageHeader>
        <BackLink url={pages.transactions.getUrl()} label="Oversikt" />
        <PageHeading>Sparing</PageHeading>
        <p>
          <b>{formattedBalance}</b>{' '}
          <span
            css={css`
              color: var(--muted-color);
            `}
          >
            + {formattedInterest} til gode i renter
          </span>
        </p>
      </PageHeader>

      {computedEarmarks
        ? computedEarmarks.map(earmark => {
            const icon = getIcon(earmark.icon) ?? <CoinsIcon />;
            return (
              <div
                key={earmark.kind === 'real' ? earmark.fid : 'other'}
                css={css`
                  ${cardStyle};

                  ${space([24], 'padding')};
                  ${space([16], 'margin-bottom')};

                  display: grid;
                  align-items: center;
                  ${space([16], 'gap')};

                  grid-template-areas:
                    'icon name balance'
                    'buttons buttons buttons';
                  grid-template-columns: max-content 1fr max-content;

                  @media ${mq.medium} {
                    grid-template-areas: 'icon name balance buttons';
                    grid-template-columns: max-content 1fr 1fr 1fr;
                  }
                `}
              >
                <span
                  css={css`
                    grid-area: icon;

                    > * {
                      display: block;
                    }
                  `}
                >
                  {icon}
                </span>
                <span
                  css={css`
                    grid-area: name;
                    min-width: 0;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  `}
                >
                  {earmark.name}
                </span>
                <span
                  css={css`
                    grid-area: balance;
                    justify-self: end;
                  `}
                >
                  {formatAmount(earmark.amount)}
                </span>
                <div
                  css={css`
                    grid-area: buttons;
                    display: grid;
                    grid-template-columns: max-content max-content;
                    ${space([16], 'gap')};

                    @media ${mq.medium} {
                      justify-self: end;
                    }
                  `}
                >
                  {/* FIXME: hide these if !CanAuthorizePayment */}
                  <span>
                    <OrgLink
                      to={pages.transferFromEarmark.getUrl(
                        earmark.kind === 'synthetic' ? 'annet' : earmark.fid,
                      )}
                    >
                      Hent ut
                    </OrgLink>
                  </span>
                  <span>
                    <OrgLink
                      to={pages.transferToEarmark.getUrl(
                        earmark.kind === 'synthetic' ? 'annet' : earmark.fid,
                      )}
                    >
                      Sett av
                    </OrgLink>
                  </span>
                </div>
              </div>
            );
          })
        : null}

      <InfoBox css={space([16], 'margin-top')}>
        <div css={fonts.font300demi}>Sparerente</div>
        <div>
          Folio følger styringsrenta fra Norges Bank, for tiden{' '}
          <b>{centralBankInterestRateFormatted}</b> per år. For innskudd over 2
          millioner er renten {lowerInterestFormatted}. Rentene betales til
          kontoen på slutten av året.
        </div>
      </InfoBox>
    </>
  );
};

// React.lazy only supports default exports:
// https://reactjs.org/docs/code-splitting.html#named-exports
// eslint-disable-next-line import/no-default-export
export default Earmarking;
