import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  PlainButton,
  fonts,
  hoverBubbleStyling,
  space,
} from 'folio-common-components';
import { isObject } from 'folio-common-utils';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { SmallArrowLeftIcon } from '../icons';
import { touchFeedback } from '../styles/touch-feedback';
import { OrgLink, useOrgPathAbsoluteUrl } from './org-navigation';

const Wrapper = styled.div`
  ${space([16], 'margin-bottom')};
`;

export const Arrow = styled(SmallArrowLeftIcon)`
  margin-right: 8px;
  position: relative;
  top: 1px;
`;

export const backLinkStyle = css`
  ${fonts.font200medium};
  color: var(--muted-color);
  text-decoration: none;

  ${hoverBubbleStyling};
  ${touchFeedback};

  @media (pointer: fine) {
    ${Arrow} {
      will-change: translate;
    }

    :active ${Arrow} {
      transition: translate 0.1s;
      translate: -2px;
    }
  }
`;

function useReferrer() {
  const { state } = useLocation();
  const referrer = isObject(state) && state.referrer;
  return typeof referrer === 'string' ? referrer : undefined;
}

function useBacklinkProps(defaultUrl: string, defaultLabel: string) {
  const { state } = useLocation();
  const referrer = useReferrer();
  const hasBacklink = isObject(state) && state.backlink;
  if (hasBacklink && referrer) {
    return {
      url: referrer,
      label: typeof state.backlink === 'string' ? state.backlink : defaultLabel,
    };
  }

  return {
    url: defaultUrl,
    label: defaultLabel,
  };
}

export const PlainBackLink: React.FC<
  React.PropsWithChildren & { url: string; label: string }
> = ({ url, label, children, ...rest }) => {
  const referrer = useReferrer();
  const urlWithOrgPath = useOrgPathAbsoluteUrl();
  const backlinkProps = useBacklinkProps(url, label);

  return (
    <OrgLink
      to={backlinkProps.url}
      aria-label={`${backlinkProps.label}, tilbake-knapp`}
      onClick={event => {
        // If this links leads to the same URL as the page we came from,
        // go back in history.
        if (urlWithOrgPath(backlinkProps.url) === referrer) {
          event.preventDefault();
          history.back();
        }
      }}
      {...rest}
    >
      {children}
    </OrgLink>
  );
};

export const BackLink: React.FC<{ url: string; label: string }> = ({
  url,
  label,
}) => {
  const backlinkProps = useBacklinkProps(url, label);
  return (
    <Wrapper>
      <PlainBackLink
        url={backlinkProps.url}
        label={backlinkProps.label}
        css={backLinkStyle}
      >
        <Arrow />
        {backlinkProps.label}
      </PlainBackLink>
    </Wrapper>
  );
};

export const BackButton: React.FC<{ onClick: () => void; label: string }> = ({
  onClick,
  label,
}) => (
  <Wrapper>
    <PlainButton
      css={backLinkStyle}
      onClick={onClick}
      aria-label={`${label}, tilbake-knapp`}
    >
      <Arrow />
      {label}
    </PlainButton>
  </Wrapper>
);
